/**
 * Module definition and dependencies
 */
angular
  .module("mockFiltresTransactions.Service", ["ngResource"])

  /**
   * Service
   */
  .service("mockFiltresTransactions", function () {
    this.getListe = function () {
      return [
        {
          id: 1,
          label: "Options",
          filtre: {
            type_id: 1,
            lot_statut: "1,2,5",
            etat: 4,
            order: "desc",
          },
          id_for_class: 1,
          template: {
            purge: false,
          },
        },
        {
          id: 2,
          label: "Options gelées",
          filtre: {
            type_id: 1,
            lot_statut: "3,4,6",
            etat: 4,
            order: "desc",
          },
          id_for_class: 1,
          template: {
            purge: false,
          },
        },
        {
          id: 3,
          label: "Réservations",
          filtre: {
            type_id: 2,
            lot_statut: 3,
            etat: 4,
            signature_procedure_status: "null,-30,-20,2",
            order: "desc",
          },
          id_for_class: 2,
          template: {
            purge: false,
          },
        },
        {
          id: 5,
          label: "Contrat généré",
          filtre: {
            type_id: 2,
            signature_procedure_status: 0,
            lot_statut: "3",
            etat: 4,
            order: "desc",
          },
          id_for_class: 2,
          template: {
            purge: false,
          },
        },
        {
          id: 17,
          label: "En cours de signature",
          filtre: {
            type_id: 4,
            lot_statut: "6",
            signature_procedure_status: "null,0,10,20,30,40,45,50,60",
            etat: 4,
            order: "desc",
          },
          id_for_class: 4,
          template: {
            purge: false,
          },
        },
        {
          id: 6,
          label: "En attente signature client",
          filtre: {
            type_id: 2,
            signature_procedure_status: 10,
            lot_statut: "3",
            etat: 4,
            order: "desc",
          },
          id_for_class: 2,
          template: {
            purge: false,
          },
        },
        {
          id: 7,
          label: "En attente validateurs internes",
          filtre: {
            type_id: 2,
            signature_procedure_status: 20,
            lot_statut: "3",
            etat: 4,
            order: "desc",
          },
          id_for_class: 2,
          template: {
            purge: false,
          },
        },
        {
          id: 8,
          label: "En attente contre signature",
          filtre: {
            type_id: 2,
            signature_procedure_status: 30,
            lot_statut: "3",
            etat: 4,
            order: "desc",
          },
          id_for_class: 2,
          template: {
            purge: false,
          },
        },
        {
          id: 9,
          label: "En attente AR24",
          filtre: {
            type_id: 2,
            signature_procedure_status: "40,45",
            lot_statut: "3",
            etat: 4,
            order: "desc",
          },
          id_for_class: 2,
          template: {
            purge: false,
          },
        },
        {
          id: 10,
          label: "En attente purge SRU",
          filtre: {
            type_id: 2,
            signature_procedure_status: 50,
            lot_statut: "3",
            etat: 4,
            order: "desc",
          },
          id_for_class: 2,
          template: {
            purge: true,
          },
        },
        {
          id: 11,
          label: "purge SRU terminée",
          filtre: {
            type_id: 2,
            signature_procedure_status: "52,65",
            lot_statut: "3",
            etat: 4,
            order: "desc",
          },
          id_for_class: 2,
          template: {
            purge: true,
          },
        },
        {
          id: 12,
          label: "En attente dépôt dossier bancaire",
          filtre: {
            type_id: 5,
            lot_statut: "3",
            signature_procedure_status: "null,0,10,20,30,40,45,50,52,60,65",
            etat: 4,
            order: "desc",
          },
          id_for_class: 2,
          template: {
            purge: false,
          },
        },
        {
          id: 13,
          label: "En attente accord bancaire",
          filtre: {
            type_id: 6,
            lot_statut: "3",
            signature_procedure_status: "null,0,10,20,30,40,45,50,52,60,65",
            etat: 4,
            order: "desc",
          },
          id_for_class: 2,
          template: {
            purge: false,
          },
        },
        {
          id: 14,
          label: "En attente offre de prêt",
          filtre: {
            type_id: 7,
            lot_statut: "3",
            signature_procedure_status: "null,0,10,20,30,40,45,50,52,60,65",
            etat: 4,
            order: "desc",
          },
          id_for_class: 2,
          template: {
            purge: false,
          },
        },
        {
          id: 15,
          label: "En attente signature notaire",
          filtre: {
            type_id: 8,
            lot_statut: "6",
            signature_procedure_status: "null,0,10,20,30,40,45,50,60",
            etat: 4,
            order: "desc",
          },
          id_for_class: 2,
          template: {
            purge: false,
          },
        },
        {
          id: 16,
          label: "Ventes",
          filtre: {
            type_id: 3,
            lot_statut: "4",
            signature_procedure_status: "null,0,10,20,30,40,45,50,52,60",
            etat: 4,
            order: "desc",
          },
          id_for_class: 3,
          template: {
            purge: false,
          },
        },
      ];
    };
  });
